export const places = [
  {
    title: "Thompsonbury",
    number: "1480",
    time: "15 minutes drive",
    image: "/images/content/places-pic-1.jpg",
    url: "/stays-product",
  },
  {
    title: "Hudsontown",
    number: "1120",
    time: "28 minutes drive",
    image: "/images/content/places-pic-2.jpg",
    url: "/stays-product",
  },
  {
    title: "Lake Marcelle",
    number: "777",
    time: "35 minutes drive",
    image: "/images/content/places-pic-3.jpg",
    url: "/stays-product",
  },
  {
    title: "New Keagan",
    number: "1432",
    time: "44 minutes drive",
    image: "/images/content/places-pic-4.jpg",
    url: "/stays-product",
  },
  {
    title: "MacGyverton",
    number: "1480",
    time: "55 minutes drive",
    image: "/images/content/places-pic-5.jpg",
    url: "/stays-product",
  },
  {
    title: "North Justen",
    number: "180",
    time: "1 hours drive",
    image: "/images/content/places-pic-6.jpg",
    url: "/stays-product",
  },
  {
    title: "Port Elyseberg",
    number: "1155",
    time: "2 hours drive",
    image: "/images/content/places-pic-7.jpg",
    url: "/stays-product",
  },
  {
    title: "Danielmouth",
    number: "2220",
    time: "5 hours drive",
    image: "/images/content/places-pic-8.jpg",
    url: "/stays-product",
  },
  {
    title: "Russelville",
    number: "480",
    time: "8 hours drive",
    image: "/images/content/places-pic-9.jpg",
    url: "/stays-product",
  },
];
