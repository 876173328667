export const best = [
  {
    title: "Name Name",
    location: "Location",
    number: "#1",
    rating: "4.9",
    color: "#58C27D",
    avatar: "/images/content/avatar-1.jpg",
    url: "/host-profile",
  },
  {
    title: "Name Name",
    location: "Location",
    number: "#2",
    rating: "5.0",
    color: "#92A5EF",
    avatar: "/images/content/avatar-2.jpg",
    url: "/host-profile",
  },
  {
    title: "Name Name",
    location: "Location",
    number: "#3",
    rating: "4.8",
    color: "#8BC5E5",
    avatar: "/images/content/avatar-3.jpg",
    url: "/host-profile",
  },
  {
    title: "Name Name",
    location: "Location",
    rating: "4.9",
    avatar: "/images/content/avatar-4.jpg",
    url: "/host-profile",
  },
  {
    title: "Name Name",
    location: "Location",
    rating: "4.9",
    avatar: "/images/content/avatar-5.jpg",
    url: "/host-profile",
  },
  {
    title: "Name Name",
    location: "Location",
    rating: "4.9",
    avatar: "/images/content/avatar-3.jpg",
    url: "/host-profile",
  },
  {
    title: "Name Name",
    location: "Location",
    rating: "4.9",
    avatar: "/images/content/avatar-1.jpg",
    url: "/host-profile",
  },
  {
    title: "Name Name",
    location: "Location",
    rating: "4.9",
    avatar: "/images/content/avatar-4.jpg",
    url: "/host-profile",
  },
  {
    title: "Name Name",
    location: "Location",
    rating: "4.9",
    avatar: "/images/content/avatar-2.jpg",
    url: "/host-profile",
  },
];
