export const flights = [
  {
    title: "Round-trip",
    items: [
      {
        price: "$3,256",
        info: "eDreams",
        directions: [
          {
            flightFrom: "AKL",
            flightTo: "SGN",
            timeFlightFrom: "6:45 AM",
            timeFlightTo: "9:45 AM",
            logo: "/images/content/emirates.svg",
          },
          {
            flightFrom: "SGN",
            flightTo: "AKL",
            timeFlightFrom: "12:45 AM",
            timeFlightTo: "3:45 AM",
            logo: "/images/content/emirates.svg",
          },
        ],
      },
      {
        price: "$3,256",
        info: "eDreams",
        directions: [
          {
            flightFrom: "AKL",
            flightTo: "SGN",
            timeFlightFrom: "6:45 AM",
            timeFlightTo: "9:45 AM",
            logo: "/images/content/emirates.svg",
          },
          {
            flightFrom: "SGN",
            flightTo: "AKL",
            timeFlightFrom: "12:45 AM",
            timeFlightTo: "3:45 AM",
            logo: "/images/content/emirates.svg",
          },
        ],
      },
      {
        price: "$3,256",
        info: "eDreams",
        directions: [
          {
            flightFrom: "AKL",
            flightTo: "SGN",
            timeFlightFrom: "6:45 AM",
            timeFlightTo: "9:45 AM",
            logo: "/images/content/emirates.svg",
          },
          {
            flightFrom: "SGN",
            flightTo: "AKL",
            timeFlightFrom: "12:45 AM",
            timeFlightTo: "3:45 AM",
            logo: "/images/content/emirates.svg",
          },
        ],
      },
      {
        price: "$3,256",
        info: "eDreams",
        directions: [
          {
            flightFrom: "AKL",
            flightTo: "SGN",
            timeFlightFrom: "6:45 AM",
            timeFlightTo: "9:45 AM",
            logo: "/images/content/emirates.svg",
          },
          {
            flightFrom: "SGN",
            flightTo: "AKL",
            timeFlightFrom: "12:45 AM",
            timeFlightTo: "3:45 AM",
            logo: "/images/content/emirates.svg",
          },
        ],
      },
      {
        price: "$3,256",
        info: "eDreams",
        directions: [
          {
            flightFrom: "AKL",
            flightTo: "SGN",
            timeFlightFrom: "6:45 AM",
            timeFlightTo: "9:45 AM",
            logo: "/images/content/emirates.svg",
          },
          {
            flightFrom: "SGN",
            flightTo: "AKL",
            timeFlightFrom: "12:45 AM",
            timeFlightTo: "3:45 AM",
            logo: "/images/content/emirates.svg",
          },
        ],
      },
    ],
  },
  {
    title: "One-way",
    items: [
      {
        price: "$3,256",
        info: "eDreams",
        directions: [
          {
            flightFrom: "AKL",
            flightTo: "SGN",
            timeFlightFrom: "6:45 AM",
            timeFlightTo: "9:45 AM",
            logo: "/images/content/emirates.svg",
          },
        ],
      },
      {
        price: "$3,256",
        info: "eDreams",
        directions: [
          {
            flightFrom: "AKL",
            flightTo: "SGN",
            timeFlightFrom: "6:45 AM",
            timeFlightTo: "9:45 AM",
            logo: "/images/content/emirates.svg",
          },
        ],
      },
      {
        price: "$3,256",
        info: "eDreams",
        directions: [
          {
            flightFrom: "AKL",
            flightTo: "SGN",
            timeFlightFrom: "6:45 AM",
            timeFlightTo: "9:45 AM",
            logo: "/images/content/emirates.svg",
          },
        ],
      },
      {
        price: "$3,256",
        info: "eDreams",
        directions: [
          {
            flightFrom: "AKL",
            flightTo: "SGN",
            timeFlightFrom: "6:45 AM",
            timeFlightTo: "9:45 AM",
            logo: "/images/content/emirates.svg",
          },
        ],
      },
      {
        price: "$3,256",
        info: "eDreams",
        directions: [
          {
            flightFrom: "AKL",
            flightTo: "SGN",
            timeFlightFrom: "6:45 AM",
            timeFlightTo: "9:45 AM",
            logo: "/images/content/emirates.svg",
          },
        ],
      },
      {
        price: "$3,256",
        info: "eDreams",
        directions: [
          {
            flightFrom: "AKL",
            flightTo: "SGN",
            timeFlightFrom: "6:45 AM",
            timeFlightTo: "9:45 AM",
            logo: "/images/content/emirates.svg",
          },
        ],
      },
      {
        price: "$3,256",
        info: "eDreams",
        directions: [
          {
            flightFrom: "AKL",
            flightTo: "SGN",
            timeFlightFrom: "6:45 AM",
            timeFlightTo: "9:45 AM",
            logo: "/images/content/emirates.svg",
          },
        ],
      },
    ],
  },
];
