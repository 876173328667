export const browse1 = [
  {
    title: "Nature house",
    counter: "650,111",
    category: "black",
    categoryText: "20% off",
    src: "/images/content/browse-pic-1.jpg",
    srcSet: "/images/content/browse-pic-1@2x.jpg",
    url: "/stays-category",
  },
  {
    title: "Nature house",
    counter: "752,336",
    category: "black",
    categoryText: "from $230",
    src: "/images/content/browse-pic-2.jpg",
    srcSet: "/images/content/browse-pic-2@2x.jpg",
    url: "/stays-category",
  },
  {
    title: "Nature house",
    counter: "433,596",
    category: "black",
    categoryText: "20% off",
    src: "/images/content/browse-pic-3.jpg",
    srcSet: "/images/content/browse-pic-3@2x.jpg",
    url: "/stays-category",
  },
  {
    title: "Nature house",
    counter: "50,596",
    category: "black",
    categoryText: "20% off",
    src: "/images/content/browse-pic-4.jpg",
    srcSet: "/images/content/browse-pic-4@2x.jpg",
    url: "/stays-category",
  },
  {
    title: "Nature house",
    counter: "650,111",
    category: "black",
    categoryText: "20% off",
    src: "/images/content/browse-pic-1.jpg",
    srcSet: "/images/content/browse-pic-1@2x.jpg",
    url: "/stays-category",
  },
  {
    title: "Nature house",
    counter: "752,336",
    category: "black",
    categoryText: "20% off",
    src: "/images/content/browse-pic-2.jpg",
    srcSet: "/images/content/browse-pic-2@2x.jpg",
    url: "/stays-category",
  },
  {
    title: "Nature house",
    counter: "433,596",
    category: "black",
    categoryText: "20% off",
    src: "/images/content/browse-pic-3.jpg",
    srcSet: "/images/content/browse-pic-3@2x.jpg",
    url: "/stays-category",
  },
  {
    title: "Nature house",
    counter: "50,596",
    category: "black",
    categoryText: "20% off",
    src: "/images/content/browse-pic-4.jpg",
    srcSet: "/images/content/browse-pic-4@2x.jpg",
    url: "/stays-category",
  },
];

export const browse2 = [
  {
    title: "Mountain house",
    counter: "650,111",
    category: "black",
    categoryText: "20% off",
    src: "/images/content/browse-pic-1.jpg",
    srcSet: "/images/content/browse-pic-1@2x.jpg",
    url: "/stays-category",
  },
  {
    title: "Mountain house",
    counter: "752,336",
    category: "black",
    categoryText: "from $230",
    src: "/images/content/browse-pic-2.jpg",
    srcSet: "/images/content/browse-pic-2@2x.jpg",
    url: "/stays-category",
  },
  {
    title: "Mountain house",
    counter: "433,596",
    category: "black",
    categoryText: "5% off",
    src: "/images/content/browse-pic-5.jpg",
    srcSet: "/images/content/browse-pic-5@2x.jpg",
    url: "/stays-category",
  },
  {
    title: "Mountain house",
    counter: "50,596",
    category: "black",
    categoryText: "15% off",
    src: "/images/content/browse-pic-4.jpg",
    srcSet: "/images/content/browse-pic-4@2x.jpg",
    url: "/stays-category",
  },
  {
    title: "Mountain house",
    counter: "650,111",
    category: "black",
    categoryText: "from $140",
    src: "/images/content/browse-pic-3.jpg",
    srcSet: "/images/content/browse-pic-3@2x.jpg",
    url: "/stays-category",
  },
  {
    title: "Mountain house",
    counter: "752,336",
    category: "black",
    categoryText: "20% off",
    src: "/images/content/browse-pic-2.jpg",
    srcSet: "/images/content/browse-pic-2@2x.jpg",
    url: "/stays-category",
  },
  {
    title: "Mountain house",
    counter: "433,596",
    category: "black",
    categoryText: "20% off",
    src: "/images/content/browse-pic-3.jpg",
    srcSet: "/images/content/browse-pic-3@2x.jpg",
    url: "/stays-category",
  },
  {
    title: "Mountain house",
    counter: "50,596",
    category: "black",
    categoryText: "20% off",
    src: "/images/content/browse-pic-4.jpg",
    srcSet: "/images/content/browse-pic-4@2x.jpg",
    url: "/stays-category",
  },
];

export const browse3 = [
  {
    title: "Nature house",
    counter: "650,111",
    category: "black",
    categoryText: "20% off",
    src: "/images/content/browse-pic-6.jpg",
    srcSet: "/images/content/browse-pic-6@2x.jpg",
    url: "/cars-category",
  },
  {
    title: "Nature house",
    counter: "752,336",
    category: "black",
    categoryText: "from $230",
    src: "/images/content/browse-pic-7.jpg",
    srcSet: "/images/content/browse-pic-7@2x.jpg",
    url: "/cars-category",
  },
  {
    title: "Nature house",
    counter: "433,596",
    category: "black",
    categoryText: "5% off",
    src: "/images/content/browse-pic-8.jpg",
    srcSet: "/images/content/browse-pic-8@2x.jpg",
    url: "/cars-category",
  },
  {
    title: "Nature house",
    counter: "50,596",
    category: "black",
    categoryText: "15% off",
    src: "/images/content/browse-pic-9.jpg",
    srcSet: "/images/content/browse-pic-9@2x.jpg",
    url: "/cars-category",
  },
  {
    title: "Nature house",
    counter: "650,111",
    category: "black",
    categoryText: "20% off",
    src: "/images/content/browse-pic-6.jpg",
    srcSet: "/images/content/browse-pic-6@2x.jpg",
    url: "/cars-category",
  },
  {
    title: "Nature house",
    counter: "752,336",
    category: "black",
    categoryText: "from $230",
    src: "/images/content/browse-pic-7.jpg",
    srcSet: "/images/content/browse-pic-7@2x.jpg",
    url: "/cars-category",
  },
  {
    title: "Nature house",
    counter: "433,596",
    category: "black",
    categoryText: "5% off",
    src: "/images/content/browse-pic-8.jpg",
    srcSet: "/images/content/browse-pic-8@2x.jpg",
    url: "/cars-category",
  },
  {
    title: "Nature house",
    counter: "50,596",
    category: "black",
    categoryText: "15% off",
    src: "/images/content/browse-pic-9.jpg",
    srcSet: "/images/content/browse-pic-9@2x.jpg",
    url: "/cars-category",
  },
];
